import $ from "jquery";

$(document).ready(function () {

    if ($('[data-background]').length > 0) {
        $('[data-background]').each(function() {
            var $background, $backgroundmobile, $this;
            $this = $(this);
            $background = $(this).attr('data-background');
            $backgroundmobile = $(this).attr('data-background-mobile');
            if ($this.attr('data-background').substr(0, 1) === '#') {
                return $this.css('background-color', $background);
            } else if ($this.attr('data-background-mobile') && device.mobile()) {
                return $this.css('background-image', 'url(' + $backgroundmobile + ')');
            } else {
                return $this.css('background-image', 'url(' + $background + ')');
            }
        });
    }

    $('.kch-ui-hero-slider').slick({
        arrows: false,
        dots: true,
        infinite: true,
        mobileFirst: true,
        appendDots: $('#kch-ui-carousel-dots'),
        prevArrow: '<button class="slide-arrow prev-arrow"><img src="https://asset.japan.travel/image/upload/v1572343009/moe_ph2_ui/chevron-left.svg"/></button>',
        nextArrow: '<button class="slide-arrow next-arrow"><img src="https://asset.japan.travel/image/upload/v1572343009/moe_ph2_ui/chevron-right.svg"/></button>',
    });

    $(".mouse-scroll").on('click', function(event) {
        if (this.hash !== "") {
          event.preventDefault();
          
          var hash = this.hash;

          $('html, body').animate({
            scrollTop: $(hash).offset().top
          }, 800);
        }
      });
});