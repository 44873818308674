import $ from "jquery";

$(document).ready(function () {



    if($(".kch-ui-standard-slider").hasClass('kch-control-with-caption')) {
        $('.kch-ui-standard-slider').on('init', function(event, slick){
            var caption = $(slick.$slides.get(0)).find('.kch-ui-standard-carousel-item', this).data('caption') || "";
            $('.kch-ui-standard-slider-caption', $(this).parent().parent()).text(caption);
        });
        $('.kch-ui-standard-slider').on('afterChange', function(event, slick, currentSlideIndex){
            var caption = $(slick.$slides.get(currentSlideIndex)).find('.kch-ui-standard-carousel-item', this).data('caption') || "";
            $('.kch-ui-standard-slider-caption', $(this).parent().parent()).text(caption);
        });
    }

    $('.kch-ui-standard-slider').slick({
        dots: false,
        infinite: false,
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    arrows: true
                }
            }],
        // appendDots: $('#kch-ui-carousel-dots'),
        prevArrow: '<button class="slide-arrow prev-arrow"><img src="/static/images/arrow-left.png"/></button>',
        nextArrow: '<button class="slide-arrow next-arrow"><img src="/static/images/arrow-right.png"/></button>',
    });

    $('.kch-ui-standard-slider-mobile').slick({
        dots: false,
        infinite: false,
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        // appendDots: $('#kch-ui-carousel-dots'),
        prevArrow: '<button class="slide-arrow prev-arrow"><img src="/static/images/arrow-left.svg"/></button>',
        nextArrow: '<button class="slide-arrow next-arrow"><img src="/static/images/arrow-right.svg"/></button>',
    });
});