import $ from "jquery";
import Swiper from 'swiper/dist/js/swiper.js';

$(document).ready(function () {


    var windowWidth = $(window).width();
    var swiperOptions;


    if(windowWidth <= 450) {
        swiperOptions = {
            observer: true,
            observeParents: true,
            effect: 'coverflow',
            preventInteractionOnTransition: true,
            rebuildOnUpdate: true,
            centeredSlides: true,
            slidesPerView:'auto',
            // slidesPerView:5,
            initialSlide: 2,
            loop: true,
            watchOverflow: true,
            // spaceBetween: -50,
            coverflowEffect: {
                modifier: 1,
                depth: 300,
                slideShadows : false,
                rotate: 0,
                stretch : 120
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + ' dot"></span>';
                }
            },
            breakpoints: {
            }
        };
    } else {
        swiperOptions = {
            observer: true,
            observeParents: true,
            effect: 'coverflow',
            preventInteractionOnTransition: true,
            rebuildOnUpdate: true,
            centeredSlides: true,
            slidesPerView:'auto',
            // slidesPerView:5,
            initialSlide: 2,
            loop: true,
            watchOverflow: true,
            // spaceBetween: -150,
            coverflowEffect: {
                modifier: 1,
                depth: 400,
                slideShadows : false,
                rotate: 0,
                stretch : 150
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + ' dot"></span>';
                }
            },
            breakpoints: {
                // 375: {
                //     slidesPerView: 2,
                //     spaceBetween: -100,
                //     coverflowEffect: {
                //         depth: 300,
                //         stretch : 100
                //     }
                // },
                // 475: {
                //     slidesPerView: 2,
                //     spaceBetween: -900,
                //     coverflowEffect: {
                //         depth: 900,
                //         stretch : 900
                //     }
                // },
                // 992: {
                //     slidesPerView: 3,
                //     spaceBetween: -300,
                // },
                // 1000: {
                //     slidesPerView: 5,
                //     spaceBetween: -400,
                //     stretch : -250,
                //     depth: 400,
                // },
                // 1200: {
                //     slidesPerView: 5,
                //     spaceBetween: -400,
                //     coverflowEffect: {
                //         stretch : -850
                //     },
                // }
            }
        };
    }






    var kchSwiper = new Swiper('.swiper-container', swiperOptions);

    $('.next').on('click', function(e) {
        e.preventDefault();
        kchSwiper.slideNext();
    });
    $('.prev').on('click', function(e) {
        e.preventDefault();
        kchSwiper.slidePrev();
    });

    // $(window).resize(function(){
    //     var ww = $(window).width();
    //     if (ww>1000){
    //         kchSwiper.params.coverflowEffect = {
    //             modifier: 1,
    //             depth: 400,
    //             slideShadows : false,
    //             rotate: 0,
    //             stretch : 150
    //         };
    //     }
    //     if (ww>468 && ww<=1000){
    //         kchSwiper.params.coverflowEffect = {
    //             modifier: 1,
    //             depth: 500,
    //             slideShadows : false,
    //             rotate: 0,
    //             stretch : 130
    //         };
    //     }
    //     if (ww<=468) {
    //         kchSwiper.params.coverflowEffect = {
    //             modifier: 1,
    //             depth: 300,
    //             slideShadows : false,
    //             rotate: 0,
    //             stretch : 110
    //         };
    //     }
    //     kchSwiper.update()
    // });
    //
    // $(window).trigger("resize")
});
