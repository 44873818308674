import $ from "jquery";
import YouTubePlayer from 'youtube-player';

$(document).ready(function () {
    // embed youtube video for main hero
    if($("#kch-ui-hero-yt-vid").is(":visible")) {
        // return;
        var youtubeID = $("#kch-ui-hero-yt-vid").data('youtubeid');
        let heroYTPlayer = YouTubePlayer('kch-ui-hero-yt-vid' , {
            videoId: youtubeID,
            cc_load_policy: 0, // closed caption
            controls: 0,
            disablekb: 0, //disable keyboard
            iv_load_policy: 3, // annotations
            playsinline: 1, // play inline on iOS
            rel: 0, // related videos
            showinfo: 0, // title
            autohide:1,
            modestbranding: 1 // youtube logo
        });
        var heroPlayerMute = true;
        var heroContext = $('.hero-youtube-video-wrapper');
        var heroVideoEventHandled = false;
        var isMobile = $(window).width() <= 768;
        self = this

        heroYTPlayer.on('stateChange', (event) => {
            if (event.data == YT.PlayerState.PAUSED ) {
                if (isMobile) {
                    $('#kch-ui-hero-yt-vid').fadeTo(300, 0);
                }
                
                $(".play-button",heroContext).show();
            }
            if (event.data == YT.PlayerState.ENDED) { // loop video
                $('#kch-ui-hero-yt-vid').fadeTo(1000, 1, function() { event.target.playVideo() } );
            }    
        });

        // hide the video until ready to play and fade in
        $('#kch-ui-hero-yt-vid').css('opacity',0);

        heroYTPlayer.on('ready', (event) => {
            self.yt_event = event;
            if(!isMobile) {
                event.target.mute();
                try {
                    $(".play-button",heroContext).hide();
                    event.target.playVideo()
                    $('#kch-ui-hero-yt-vid').fadeTo(1000, 1, function() {} );
                } catch (e) {
                    console.log('kch-youtube:error',e);
                }
            }
        })

        var handleVideoToggle = function () {
            if(!heroPlayerMute) {
                $(".kch-ui-sound-toggle-on-icon",heroContext).hide();
                $(".kch-ui-sound-toggle-off-icon", heroContext).show();
                heroYTPlayer.mute();
            } else {
                $(".kch-ui-sound-toggle-on-icon", heroContext).show();
                $(".kch-ui-sound-toggle-off-icon", heroContext).hide();
                heroYTPlayer.unMute()
            }
            heroPlayerMute = !heroPlayerMute;
        };

        $(".kch-ui-sound-toggle", heroContext).on('click touchend', function(e) {
            e.stopImmediatePropagation();
            if(e.type == "touchend") {
                heroVideoEventHandled = true;
                handleVideoToggle();
            }
            else if(e.type == "click" && !heroVideoEventHandled) {
                handleVideoToggle();
            }
            else {
                heroVideoEventHandled = false;
            }
        });

        $(".play-button", heroContext).on('click', function(e) {
            e.stopImmediatePropagation();
            if(!heroVideoEventHandled) {
                $(".play-button",heroContext).hide();
                $('#kch-ui-hero-yt-vid').fadeTo(1000, 1, function() {});    
                self.yt_event.target.playVideo();           
            } else {
                heroVideoEventHandled = false;
            }
        });

        $(".kch-ui-video-hero-content", heroContext).on('click', function(e) {
            e.stopImmediatePropagation();
                if(!heroVideoEventHandled) {
                    self.yt_event.target.pauseVideo();
                } else {
                    heroVideoEventHandled = false;
                }
        });

    }
});