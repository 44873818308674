import $ from "jquery";

$(document).ready(function () {
    var modal = $('#videoModal');

    $('.video-iframe-wrapper').on('click', function(e) {
        e.preventDefault();
        $('#videoModal').show();
    });

    $('.modal').on('click', function(e) {
        $('.video-modal-content-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
        $('#videoModal').hide();
    });


    window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    }
});

