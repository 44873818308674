import $ from "jquery";

$(document).ready(function () {

  $('.kch-ui-carousel-with-img-slider').slick({
    dots: false,
    infinite: true,
    mobileFirst: true,
    prevArrow: '<button class="slide-arrow prev-arrow"><img src="https://asset.japan.travel/image/upload/v1586356705/moe_ph2_ui/icon_016_chevron_left_black.svg"/></button>',
    nextArrow: '<button class="slide-arrow next-arrow"><img src="https://asset.japan.travel/image/upload/v1586356705/moe_ph2_ui/icon_015_chevron_right_black.svg"/></button>',
    responsive: [
      {
        breakpoint: 100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  });
});