import 'normalize.css/normalize.css';
import '../_scss/styles.scss';

import $ from 'jquery';
import './cookie_bar';
import 'slick-carousel';

import './hero';
import './carousel-standard';
import './carousel-two-part';
import './carousel-wide';
import './carousel-large-center';
import './helpful-link-widget';
import './youtube-embed';
import './carousel-with-img-caption';
import './subnav-dropdown';
import './video-modal';
import './video-container-link';



/**
 *
 * @private
 */
var _kchMoeMainMenu = function () {

    $(".header__main-menu .nav-item").hover(
        function () {
            $(this).find(".link-list--sub").stop(true, true).slideDown('medium');
        },
        function () {
            $(this).find(".link-list--sub").stop(true, true).delay(150).slideUp('fast');
        }
    );


    $(".menu-toggle").click(function () {
        $("#kch-control-mobile-slide-out").width(255);
        $(".kch-ui-mobile-main-header").hide();
    });

    $(".kch-control-close-slide-out").click(function () {
        $("#kch-control-mobile-slide-out").width(0);
        $(".kch-ui-mobile-main-header").show();
    });

    $('body').on('click touchstart', function (e) {
        var selectedElement = $(e.target);
        if (!selectedElement.closest('#kch-control-mobile-slide-out').length && $("#kch-control-mobile-slide-out").width() > 0) {
            $("#kch-control-mobile-slide-out").width(0);
            $(".kch-ui-mobile-main-header").show();
        }
    });
};

var kchMoeHoverEvents = (function () {
    var init = function ($params) {
        _kchMoeMainMenu();
    };

    return {
        init: init,
    };

})();

/**
 *
 */
var kchMoeViewportOffset = (function () {

    //////////////////////////////////////////////////////////
    ////  Private Methods
    //////////////////////////////////////////////////////////

    var _offsetWatcher = function () {

        var scroll;
        var offsetRange = 7;

        setState();

        $(window).on("scroll", function () {
            setState();
        });

        function setState() {

            scroll = $(window).scrollTop();

            var hasOffset = $(".kch-ui-header").hasClass('no-offset');

            if (scroll >= offsetRange) {
                if (!hasOffset) {
                    // below the top of viewport
                    $(".kch-ui-header").removeClass('kch-at-top');
                    $(".kch-ui-header").addClass('kch-offset-from-top');
                }
            } else {
                if (!hasOffset) {
                    // at top of viewport
                    $(".kch-ui-header").addClass('kch-at-top');
                    $(".kch-ui-header").removeClass('kch-offset-from-top');
                }
            }

        }

    };

    //////////////////////////////////////////////////////////
    ////  Public Methods
    //////////////////////////////////////////////////////////

    var init = function ($params) {
        _offsetWatcher();
    };

    //////////////////////////////////////////////////////////
    ////  Returned Methods
    //////////////////////////////////////////////////////////

    return {
        init: init
    };

})();


$(document).ready(function () {

    // hover event functions
    kchMoeHoverEvents.init();
    kchMoeViewportOffset.init();

});
