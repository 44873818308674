import $ from "jquery";

$(document).ready(function () {


    // Slider Init
    $('.kch-ui-standard-two-part-slider').on('init', function(event, slick){
        var title = $(slick.$slides.get(0)).find('.kch-ui-standard-carousel-item', this).data('title') || "";
        var content = $(slick.$slides.get(0)).find('.kch-ui-standard-carousel-item', this).data('content') || "";
        $('.kch-ui-section-slider-title', $(this).parent()).text(title);
        $('.kch-ui-section-slider-content', $(this).parent()).text(content);
    });

    // slider on change update content box with relevant information
    $('.kch-ui-standard-two-part-slider').on('afterChange', function(event, slick, currentSlideIndex){
        var title = $(slick.$slides.get(currentSlideIndex)).find('.kch-ui-standard-carousel-item', this).data('title') || "";
        var content = $(slick.$slides.get(currentSlideIndex)).find('.kch-ui-standard-carousel-item', this).data('content') || "";
        $('.kch-ui-section-slider-title', $(this).parent()).text(title);
        $('.kch-ui-section-slider-content', $(this).parent()).text(content);
    });



    $('.kch-ui-standard-two-part-slider').slick({
        dots: true,
        infinite: true,
        mobileFirst: true,
        prevArrow: '<button class="slide-arrow prev-arrow"><img src="https://asset.japan.travel/image/upload/v1572343009/moe_ph2_ui/chevron-left.svg"/></button>',
        nextArrow: '<button class="slide-arrow next-arrow"><img src="https://asset.japan.travel/image/upload/v1572343009/moe_ph2_ui/chevron-right.svg"/></button>',
    });
});