import $ from 'jquery';
import * as Cookies from "js-cookie";

var o = {};
var r = {};

r.b = {
    $window: $(window),
    $document: $(document),
    $html: $("html"),
    $body: $("body"),
    setCookieBar: function() {
        return this.$body.addClass("body--cookie-bar-active"), this
    },
    resetCookieBar: function() {
        return this.$body.removeClass("body--cookie-bar-active"), this
    },
    isCookieBarActive: function() {
        return this.$body.hasClass("body--cookie-bar-active")
    }
}

o.a = {
    cookieBar: {
        name: "kch-cookieBar",
        value: {
            active: "1"
        },
        expires: 30
    },
    favoriteBalloon: {
        name: "kch-favoriteBalloon",
        value: {
            active: "1"
        },
        expires: 30
    }
}
let a = function() {
    function e(e) {
        this.$host = e.$host, this.$button = this.$host.find(".mod-cookie-bar__button")
    }
    return e.prototype.init = function() {
        return this.isCookieActive() || this.showCookieBar().addClickListener(), this
    }, e.prototype.isCookieActive = function() {
        var e = o.a.cookieBar,
            t = e.value.active;
        return Cookies.get(e.name) === t
    }, e.prototype.setCookie = function() {
        var e = o.a.cookieBar;
        return Cookies.set(e.name, e.value.active, {
            expires: e.expires
        }), this
    }, e.prototype.showCookieBar = function() {
        return r.b.setCookieBar(), this
    }, e.prototype.addClickListener = function() {
        var t = this;
        return t.$button.on("click." + e._name, function() {
            t.setCookie(), r.b.resetCookieBar()
        }), t
    }, e._name = "ModCookieBar", e
}();

var e = new a({
    $host: $(this)
});


$(".mod-cookie-bar").each(function() {
    var e = new a({
        $host: $(this)
    });
    e.init();
    // oe.push(e);

});
