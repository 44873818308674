import $ from "jquery";

$(document).ready(function () {

    var clearAllHelpfulLinkActiveStatuses = function () {

        $(".kch-ui-helpful-link-item").each(function (index) {
            $( this ).removeClass('active');
        });
    };


    // initialize state
    clearAllHelpfulLinkActiveStatuses();
    $(".kch-ui-helpful-link-item:first").addClass('active');


    $(".kch-ui-helpful-link-item").on('click' , function () {
        clearAllHelpfulLinkActiveStatuses();
        $( this ).addClass('active');
    });

});