import $ from "jquery";

$(document).ready(function () {

    // Slider Init
    $('.kch-ui-widget-controlled-slider').on('init', function(event, slick){
        var title = $(slick.$slides.get(0)).find('.kch-ui-controlled-slider-item', this).data('title') || "";
        var content = $(slick.$slides.get(0)).find('.kch-ui-controlled-slider-item', this).data('content') || "";
        var link = $(slick.$slides.get(0)).find('.kch-ui-controlled-slider-item', this).data('link');
        $('.kch-ui-controlled-slider-box-title', $(this).parent()).text(title);
        $('.kch-ui-controlled-slider-box-content', $(this).parent()).text(content);
        if(link)
            $('.kch-ui-controlled-slider-box a', $(this).parent()).prop("href", link);
        else
            $('.kch-ui-controlled-slider-box a', $(this).parent())[0].removeAttribute('href');
        // also hide arrows if the lenght is only 1
        if(slick.$slides.length <= 1) {
            $('.kch-ui-widget-controlled-slider-controls',$(this).parent()).hide();
        }
    });

    // slider on change update content box with relevant information
    $('.kch-ui-widget-controlled-slider').on('afterChange', function(event, slick, currentSlideIndex){
        var title = $(slick.$slides.get(currentSlideIndex)).find('.kch-ui-controlled-slider-item', this).data('title') || "";
        var content = $(slick.$slides.get(currentSlideIndex)).find('.kch-ui-controlled-slider-item', this).data('content') || "";
        var link = $(slick.$slides.get(currentSlideIndex)).find('.kch-ui-controlled-slider-item', this).data('link');
        if(link)
            $('.kch-ui-controlled-slider-box a', $(this).parent()).prop("href", link);
        else
            $('.kch-ui-controlled-slider-box a', $(this).parent())[0].removeAttribute('href');
        $('.kch-ui-controlled-slider-box-title', $(this).parent()).text(title);
        $('.kch-ui-controlled-slider-box-content', $(this).parent()).text(content);
    });

    $('.kch-ui-widget-controlled-slider-left').each(function(index, element){
        $(element).on('click', function (){
          $(element).closest('.kch-ui-carousel-wide-container').find('.kch-ui-widget-controlled-slider').slick('slickPrev');
        });
    });

    $('.kch-ui-widget-controlled-slider-right').each(function(index, element){
        $(element).on('click', function (){
          $(element).closest('.kch-ui-carousel-wide-container').find('.kch-ui-widget-controlled-slider').slick('slickNext');
        });
    });

    // Invoke the slider
    $('.kch-ui-widget-controlled-slider').slick({
        dots: false,
        arrows:  false,
        infinite: true,
        mobileFirst: true,
        cssEase: 'ease-out',
        useTransform: false
    });

});
